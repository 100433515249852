<template>
  <div class="website-index-wrapper">
    <!-- 官网轮播 -->
    <Banners/>
    <!-- 楼层模块 -->
    <div class="floor" ref="floor">
      <!-- 分子公司 -->
      <BranchCompany ref="floorItem"/>
      <!-- 产品手册 -->
<!--      <ProductManual />-->
      <!-- 员工风采 -->
<!--      <EmployeeWind ref="floorItem"/>-->
      <!-- 企业动态 -->
<!--      <EnterpriseDynamics ref="floorItem"/>-->
      <!-- 科研学术 -->
<!--      <ScientificResearch ref="floorItem"/>-->
    </div>
    <!--      侧边导航 -->
<!--    <SideNavigation-->
<!--        :sideList="sideList"-->
<!--        :floorItemHeights="floorItemHeights"-->
<!--    />-->

  </div>
</template>

<script>
//官网轮播区域
import Banners from "./components/Banners";
//分子公司
import BranchCompany from "./components/BranchCompany";
//产品手册
import ProductManual from "./components/ProductManual";
//员工风采
import EmployeeWind from "./components/EmployeeWind";
//企业动态
import EnterpriseDynamics from "./components/EnterpriseDynamics";
//科研学术
import ScientificResearch from "./components/ScientificResearch";
//楼层侧边栏
import SideNavigation from "./components/SideNavigation";


export default {
  name: "websiteIndex",
  components: {
    Banners,
    BranchCompany,
    ProductManual,
    EmployeeWind,
    EnterpriseDynamics,
    ScientificResearch,
    SideNavigation
  },
  data() {
    return {
      //侧边栏数据
      sideList: [],
      //对应楼层高度
      floorItemHeights:[],
      upwardOffsetNum:300,
    }
  },
  mounted() {
    /*
       @ author: 孙杭杭
       @ action: 初始化侧边导航栏数据
    */
    this.sideListInit()
  },
  methods: {
    sideListInit() {
      const {upwardOffsetNum} = this
      this.$nextTick(() => {
        let floorWrapper = this.$refs.floor,
            floorItemTitle = ''
        const {sideList, floorItemHeights} = this,
            floorItemEls = [...floorWrapper.children]
        floorItemEls.forEach((floorItem, floorItemIndex) => {
          floorItemTitle = floorItem?.children[0]?.children[0]?.innerText
          floorItemHeights.push(floorItem.offsetTop - upwardOffsetNum)
          sideList.push({
            sideId: floorItemIndex + 1,
            sideTitle: floorItemTitle
          })
        })
      })
    }
  }
}
</script>

<style lang="scss">
.website-index-wrapper {
  margin: 0 auto;
  position: relative;
  z-index: 1000;
}


.homeWrap, .floor {
  width:100%;
  margin: 0 auto;
}


.slideNavigationWrapper {
  position: fixed;
  right:50px;
  top:70%;
  transform: translateY(-50%);
  z-index: 10000;
}

.foot {
  width: 100%;
  height: 100px;

}

</style>
