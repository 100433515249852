<template>
  <div class="branch-company-wrapper">
    <div class="title flex-rowBetweenColCenter">
      <div class="title-text flex-rowBetweenColCenter">
        <span class="el-icon-caret-right icon"></span>
        <p class="text">分子公司</p>
      </div>
      <span>更多<i class="el-icon-arrow-right"></i></span>
    </div>
    <div class="content flex-rowBetweenColStart">
      <div class="company-card-wrapper"
           v-for="(item, index) in companyList"
           :key="index"

      >
        <el-card
            :body-style="{ padding: '0px' }"
        >
          <div class="content-text">
            <p class="singleRow">{{ item.title }}</p>
            <p>{{ item.content }}</p>
          </div>
          <div class="company-pic flex-rowCenterColCenter">
            <img v-lazy="item.cover" object-fit="fill" class="image">
          </div>


        </el-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BranchCompany",
  data() {
    return {
      companyList: [
        {
          "classifyList": [
            {
              "id": 7,
              "title": "公司简介(辰极科技)",
              "rightData": {
                "tabType": "companyProfile",
                "title": "北京辰极科技有限公司",
                "content": "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京辰极防务科技有限公司北京辰极科技有限公司是一家专注于高科技智能设备领域的科技公司，设立于北京市丰台区美丽的永定河畔，与美丽的宛平城依依相望。企业自成立以来，始终坚持以人为本、诚信立业的经营原则，荟萃业界精英，由国内知名高校博士团队和资深高科技智能系统行业从业者所创立的精英科研团队，在探测设备、导航设备、通信设备、导航仪及惯导等领域具有先进理念和关键核心技术。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极科技研发的多型号机械式、固态式激光雷达、多型号高精度导航测量板卡、车载自组网通信设备、低成本组网数据链设备、靶场测量设备等多型号设备已广泛应用于多个行业，使企业在激烈的市场竞争中始终保持竞争力，实现企业快速、稳定地发展。",
                "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyProfile.jpg"),
              }
            },
            {
              "id": 11,
              "title": "企业架构",
              "rightData": {
                "tabType": "companyStructure",
                "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyStructure.png"),
              }
            },
            {
              "id": 13,
              "title": "企业文化",
              "rightData": {
                "tabType": "enterpriseCulture",
                "title": "“创、质、和、法”，既是企业的文化，也是对企业和员工的基本要求。",
                "list": [
                  {
                    text: "创",
                    content: "以创新精神做企业，以创新思维研技术。"
                  },
                  {
                    text: "质",
                    content: "产品要有过硬质量，做人要有优良品质。"
                  },
                  {
                    text: "和",
                    content: "内部关系融洽和气，对外交往合作共赢。"
                  },
                  {
                    text: "法",
                    content: "遵守国家法律法规和保密规定，遵守公司规章制度和商业机密。"
                  },

                ],
                "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture.png"),
                "cover2": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png")
              }
            },
            {
              "id": 15,
              "title": "公司资质",
              "rightData": {
                "tabType": "companyQualification",
                list: [
                  {
                    text: "中关村高新技术企业认定"
                  },
                  {
                    text: "高新技术企业证书"
                  },
                  // {
                  //   text: "武器装备承研承制认证"
                  // },
                  // {
                  //   text: "军工保密二级资质"
                  // },
                  {
                    text: "北京市“专精特新”中小企业"
                  },
                  {
                    text: "知识产权试点单位证书"
                  },
                ],
                cover: require('@/assets/websiteImages/cjzh-images/cjzh-companyQualification.png'),
              }
            },
            {

              "id": 9,
              "title": "公司团队",
              "rightData": {
                "tabType": 'companyTeam',
                "list": [
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx",
                    position: "创始人",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx2",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx3",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx4",
                    position: "研发总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx5",
                    position: "研发副总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx6",
                    position: "市场销售中心总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx7",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx8",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx9",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx10",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  }
                ],

              }
            },
            {
              "id": 25,
              "title": "核心技术",
              "rightData": {
                "tabType": "coreTechnology",
                "list": [
                  {
                    icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon1.png"),
                    title: "低轨卫星终端",
                    text: "可实现高动态环境下低轨卫星信号的快速捕获和稳定跟踪，宽带频率切换下的相控阵天线高精度指向技术，具备低成本、轻量化、低功耗等特点。"
                  },
                  {
                    icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon2.png"),
                    title: "数据链",
                    text: "具有体系统一、自主演进、弹性互联、韧性顽存、一端多模能力特点的低延时、远距离、小型化的数据链路可靠传输技术。"
                  },
                  {
                    icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon3.png"),
                    title: "自组网",
                    text: "可实现自组网通信链路的远距离高速率多节点长加密。"
                  },
                  {
                    icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon4.png"),
                    title: "卫星通信終端基带技术",
                    text: "具备小型化、低功耗特点。"
                  },
                ],
                "cover": require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology.png"),
              }
            },
            {
              "id": 26,
              "title": "公司产品",
              "rightData": {
                "tabType": "companyProducts",
                "productCharacteristicsTitle": "产品特点",
                "productDomainTitle": "应用领域",
                "list": [
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts1.png"),
                    "title": "CJ-644419-G",
                    "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。手提箱是一款体积小、重量轻便于携带的产品，一般用于指挥器材、笔记本电脑、照相录像机等文化办公用品的存储和运输。",
                    "domainText": "可用于野外作业的各种场景，盛放各种精密仪器防止破损。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts2.png"),
                    "title": "CJ-906050-G",
                    "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。手提箱是一款体积小、重量轻便于携带的产品，一般用于指挥器材、笔记本电脑、照相录像机等文化办公用品的存储和运输。",
                    "domainText": "可用于野外作业的各种场景，盛放各种精密仪器防止破损。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts3.png"),
                    "title": "车载自组网通信设备 RE-A020LY",
                    "characteristicsText": "单个设备故障不会影响整个系统其它设备的使用不依赖基站、高效、快速组成无中心的无线宽带网络，支持多跳中继、能有效拓展无线网络的覆盖半径/IP/UDP网络协议线，全IP组网，易于互联，通信距离：单跳传输≥6km（通视环境）。",
                    "domainText": "应急通信、车载指挥、森林防火、石油勘探。  ",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts4.png"),
                    "title": "抛物面天线 RE-T240Z",
                    "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。支持对天线、发射机、卫星信道设备等主要设备工作状态、参数的监视和控制。支持宽带通信网、抗强干扰通信网之间的话音和 IP 数据业务互联互通。支持宽带通信网、抗强干扰通信网与军用自动电话网之间话音业务互通。",
                    "domainText": "通用型 2.4 米 Ka/Ku 双频段卫星通信车主要编配于战区及军以上作战单元。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts5.png"),
                    "title": "抛物面天线 RE-T050F",
                    "characteristicsText": "支持抗强电磁脉冲攻击能力；多轨道、有信标、无信标自跟踪能力； 支持 Ka 宽频工作能力；支持宽带、抗强等多种波形应用；支持话音、同/异步数据、IP 数据、短消息和集群等多业务处理； 支持远程接入和组网通信，管理控制、人机交互和升级扩展能力；支持小型化低功耗、快速展开撤收。",
                    "domainText": "主要编配于核反击作战力量、特种作战部队。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts6.png"),
                    "title": "RE-A020SY",
                    "characteristicsText": "支持 7×24 小时持续工作，IP 67 防护等级，防水防盐雾；支持视频、语音、位置、传感器等各类基于 IP 的数据传输;支持自动频谱迁移、跳频工作模式；支持 MIMO 多天线技术，最大通信速率大于 100Mbps。",
                    "domainText": "应急通信、岸船通信、森林防火、石油勘探。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts7.png"),
                    "title": "RE-A020SY",
                    "characteristicsText": "支持 7×24 小时持续工作，IP 67 防护等级，防水防盐雾；支持视频、语音、位置、传感器等各类基于 IP 的数据传输;支持自动频谱迁移、跳频工作模式；支持 MIMO 多天线技术，最大通信速率大于 100Mbps。",
                    "domainText": "应急通信、岸船通信、森林防火、石油勘探。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts8.png"),
                    "title": "RE-A020SY",
                    "characteristicsText": "支持 7×24 小时持续工作，IP 67 防护等级，防水防盐雾；支持视频、语音、位置、传感器等各类基于 IP 的数据传输;支持自动频谱迁移、跳频工作模式；支持 MIMO 多天线技术，最大通信速率大于 100Mbps。",
                    "domainText": "应急通信、岸船通信、森林防火、石油勘探。",
                  },
                ]
              }
            },
            {
              "id": 24,
              "title": "合作伙伴",
              "rightData": {
                "tabType": "partners",
                "cover":require('@/assets/websiteImages/cjzh-images/cjzh-partners.png')
              }
            },
            {
              "id": 30,
              "title": "企业愿景",
              "rightData": {
                "tabType": "enterpriseVision",
                "text": "这三年时间为公司稳定发展的成熟期，主要工作是巩固企业成果，全方位立体化的建设公司。在成熟期，公司自主知识产权的科研成果具有国内外领先水平，产品在特定的行业领域和巨大民用市场得到广泛应用，公司建立了优良的品牌和企业诚信，与优良用户建立产期稳定的客户关系，具有完善高效的供应链体系，销售已占有较大的市场规模，同时具有科学合理人才队伍，管理制度具有领先优势，公司已完全具备科创板上市条件。",
                "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseVision1.png"),
              }
            },

          ],
          "cover": require('@/assets/websiteImages/home/BranchCompany3.png'),
          "id": 44,
          "title": "北京辰极科技有限公司",
          "logoText": "辰极科技",
          "content": "经营范围：无人系统产品及平台测试服务、智能硬件设计与开发。",
          "logo":require('@/assets/websiteImages/home/cjkj-logo.png')
        },
        {
          "classifyList": [
            {
              "id": 7,
              "title": "公司简介(辰极防务)",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "companyProfile",
                "title": "北京辰极防务科技有限公司",
                "content":"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极防务是一家专注提供人体防护、物资防护、装备防护等高端产品，集设计、研发、生产、销售于一体的智能化高新技术企业。由来自于清华大学、国防科技大学等高等院校（所）创立的精英科研团队，在先进新材料开发及防务器材装备研制等领域具有先进理念和关键核心技术，在军事需求理解、产品研制、系统解决方案等方面具有丰富经验。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp公司总部设在北京于2020年成立，地址位于北京市丰台区玲珑汇6层，主要对接客户需求并进行设计研发。目前在上海、河北、江苏、浙江、安徽等地均设有生产基地，对客户的不同需求进行产品开模定制、产品加工、产品检验、仓储配送等，提供满足客户需求的产品及服务。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京辰极防务科技有限公司把“追求卓越，发展创新”作为企业战略目标，公司研发的无伞空投物资包装箱、人体防护器材、电子防务装备等已广泛应用于多个领域，使企业在激烈的竞争中始终保持竞争力，实现公司快速稳定发展。做品牌产品，为国防和军队事业做出贡献。",
                "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProfile.png"),
              }
            },
            {
              "id": 11,
              "title": "企业架构",

              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "companyStructure",
                "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyStructure.png"),
              }
            },
            {
              "id": 13,
              "title": "企业文化",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "enterpriseCulture",
                "title": "“追求卓越，发展创新”",
                "content":"目前在上海、河北、江苏、浙江、安徽等地均设有生产基地，对客户的不同需求进行产品开模定制、产品加工、产品检验、仓储配送等，提供满足客户需求的产品及服务。",
                "cover": require("@/assets/websiteImages/cjfw-images/cjfw-enterpriseCulture1.png"),
                "cover2": require("@/assets/websiteImages/cjfw-images/cjfw-enterpriseCulture2.png")
              }
            },
            {
              "id": 15,
              "title": "公司资质",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "companyQualification",
                "content":"公司研发团队以清华、北航、北理、哈工大、西工大等高校的博士、硕士和毕业人员为主体，包括主流游戏和仿真行业的资深从业者，在需求分析、方案论证、软件开发、系统集成、技术转化等方面具有丰富的经验和成果积累。",
                cover: require('@/assets/websiteImages/cjfw-images/cjfw-companyQualification.png'),
              }
            },
            {

              "id": 9,
              "title": "公司团队",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": 'companyTeam',
                // "list": [
                //   {
                //     cover: require("@/assets/websiteImages/cjfw-images/cjfw-party-1.png"),
                //     name: "章双全",
                //     position: "研发部：研发总监",
                //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/cjfw-images/cjfw-party-2.png"),
                //     name: "徐婷婷",
                //     position: "市场部：市场总监",
                //     description: "个人简介：全面负责市场部客户开发、客户维护工作。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/cjfw-images/cjfw-party-3.png"),
                //     name: "郑铁宁",
                //     position: "研发部：设计总监",
                //     description: "个人简介：负责公司项目的研发设计。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/cjfw-images/cjfw-party-4.png"),
                //     name: "谭浩",
                //     position: "市场部：市场部副总监",
                //     description: "个人简介：全面负责管理市场部外部工作。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/cjfw-images/cjfw-party-5.png"),
                //     name: "王华",
                //     position: "研发部：研发副总监",
                //     description: "个人简介：全面负责管理市场部内部工作。"
                //   }
                // ],
                "list": [
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx",
                    position: "创始人",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx2",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx3",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx4",
                    position: "研发总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx5",
                    position: "研发副总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx6",
                    position: "市场销售中心总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx7",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx8",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx9",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx10",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  }
                ],
              }
            },
            {
              "id": 25,
              "title": "核心技术",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "coreTechnology",
                "list": [
                  {
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon1.png"),
                    title: "拥有专业的工业设计人才。",
                  },
                  {
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon2.png"),
                    title: "掌握新型复合材料的先进配方。",
                  },
                  {
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon3.png"),
                    title: "利用核心技术可以自主研发、生产满足市场需求的智能化产品。",
                  },
                  {
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon4.png"),
                    title: "智能包装和智能防护类的轻量化组合。",
                  },
                  {
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon5.png"),
                    title: "为基地提供全面的低空防御领域的技术方案和产品。",
                  },{
                    icon: require("@/assets/websiteImages/cjfw-images/cjfw-coreTechnology-icon6.png"),
                    title: "智能化无人系统和智能算法方面经验丰富。",
                  },

                ],
              }
            },
            {
              "id": 26,
              "title": "公司产品",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "companyProducts",
                "productCharacteristicsTitle": "产品特点",
                "productDomainTitle": "应用领域",
                "list": [
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts1.png"),
                    "title": "CJ-644419-G",
                    "characteristicsText": "手提箱是一款体积小、重量轻便于携带的产品，一般用于指挥器材、笔记本电脑、照相录像机等文化办公用品的存储和运输。",
                    "domainText": "可用于野外作业的各种场景，盛放各种精密仪器防止破损。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts2.png"),
                    "title": "CJ-906050-G",
                    "characteristicsText": "手提箱是一款体积小、重量轻便携带的产品，一般用于指挥器材、笔记本电脑、照相录像等文化办公用品的存储和运输。",
                    "domainText": "可用于野外作业的各种场景，盛放各种精密仪器防止破损。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts3.png"),
                    "title": "CJ-705040-G",
                    "characteristicsText": "中型器材箱用于各种中小型的器材和物资的防护和运输，能够有效减轻外部环境对内置器材的冲击，实现全方位立体防护。",
                    "domainText": "可用于各种户外和室内的器械及物资的存储和防护，抗压性强且长期存放物品避免受潮。  ",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts4.png"),
                    "title": "CJ-504017-Ⅱ-G",
                    "characteristicsText": "适用于部队常规训练和弹药的运输，因为体积较小所以携带方便，方便携带和保护弹药安全 。",
                    "domainText": "弹药/体能训练箱适用于部队常规训练和弹药的运输使用。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts5.png"),
                    "title": "CJ-806030-G",
                    "characteristicsText": "弹药/体能训练箱适用于部队常规训练和弹药的运输使用。",
                    "domainText": "可用于各种户外和室内的器械及物资的存储和防护，抗压性强且长期存放物品避免受潮。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts6.png"),
                    "title": "CJ-806054-G",
                    "characteristicsText": "弹药/体能训练箱适用于部队常规训练和弹药的运输使用。",
                    "domainText": "可用于各种户外和室内的器械及物资的存储和防护，抗压性强且长期存放物品避免受潮。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts7.png"),
                    "title": "CJ-535775-G",
                    "characteristicsText": "适合装载通用军械维修工具及小型维修设备，供部队遂行训练、演习等非战争军事行动和战时通用车辆的维修作业使用。",
                    "domainText": "在室外或者野外帐篷里，部队遂行训练、演习等非战争军事行动和战时通用车辆的维修作业。",
                  },
                  // {
                  //   "cover": require("@/assets/websiteImages/cjfw-images/cjfw-companyProducts8.png"),
                  //   "title": "CJ-604332-G",
                  //   "characteristicsText": "拉杆箱适用于较重的产品和物资的防护和运输，能够有效减轻外部环境对内置器材的冲击，全方位保护产品。",
                  //   "domainText": "拉杆箱可以适用于各种户外和室内的器材及物资的存储和防护，抗压力强且长期存放物品避免受潮。",
                  // },
                ]
              }
            },
            {
              "id": 24,
              "title": "合作伙伴",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "partners",
                "cover":require('@/assets/websiteImages/cjfw-images/cjfw-partners.png')
              }
            },
            {
              "id": 30,
              "title": "企业愿景",
              "rightData": {
                "companyType":"cjfwCompany",
                "tabType": "enterpriseVision",
                "cover": require("@/assets/websiteImages/cjfw-images/cjfw-enterpriseVision1.png"),
              }
            },

          ],
          "cover": require('@/assets/websiteImages/home/BranchCompany2.png'),
          "id": 42,
          "title": "北京辰极防务科技有限公司",
          "logoText": "辰极防务",
          "content": "经营范围：智能包装箱和智能温控服的产品研发设计，低空防御系统（如机场智能驱鸟系统和反无人系统）的全面解决方案。",
          "logo":require('@/assets/websiteImages/home/cjfw-logo.png')
        },
        {
          "classifyList": [
            {
              "id": 7,
              "title": "公司简介(王者荣耀)",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "companyProfile",
                "title": "北京王者荣耀有限公司",
                "content":"&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;北京王者荣耀科技有限公司成立于2016年，位于京西美丽的永定河畔。公司自成立以来，一直致力于指控系统、游戏仿真以及大数据领域的技术创新与研究，无论是在产品孵化、核心竞争力、服务品质上都保持着旺盛的生命力和丰富的市场活力。公司核心团队成员有高学历、高技术、高专业素养的特点，为公司承接项目奠定了扎实的人才基础和研发实力。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;公司秉承“创新、灵动、卓越、专注”的企业宗旨，把科技创新、行业领先和品质追求作为企业战略目标，专注于应用软件、模拟仿真、功能游戏等应用领域和技术积累，凭借平台化、定制化、智能化的特色产品与领先的技术创新，在产品应用上得到用户的广泛认同和良好的口碑，在市场竞争中始终保持良好的竞争力，实现企业快速、稳定的可持续性发展。",
                "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProfile.png"),
              }
            },
            {
              "id": 11,
              "title": "企业架构",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "companyStructure",
                "cover": require("@/assets/websiteImages/wzry-images/wzry-companyStructure.png"),
              }
            },
            {

              "id": 13,
              "title": "企业文化",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "enterpriseCulture",
                "title": "“智慧创新，服务先行”",
                "content":"公司秉持着“智慧创新、服务先行”的理念，通过汇聚顶尖人才、为军工企业单位、科研院所等用户提供先进智能、安全可控的软件产品解决方案。",
                "cover": require("@/assets/websiteImages/wzry-images/wzry-enterpriseCulture1.png"),
                "cover2": require("@/assets/websiteImages/wzry-images/wzry-enterpriseCulture2.png"),
              }
            },
            {
              "id": 15,
              "title": "公司资质",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "companyQualification",
                "content":"近年来，公司承研了多款军事训练游戏项目、指挥控制平台项目、模拟仿真推演项目，相关产品得到了行业内各单位的广泛应用，获得了各界的认可，累积了成熟的产业孵化经验。现已拥有多项专利、软件著作权等数十项知识产权。",
                cover: require('@/assets/websiteImages/wzry-images/wzry-companyQualification.png'),
              }
            },
            {

              "id": 9,
              "title": "公司团队",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": 'companyTeam',
                // "list": [
                //   {
                //     cover: require("@/assets/websiteImages/wzry-images/wzry-party-1.png"),
                //     name: "金满刚",
                //     position: "研发部：研发总监",
                //     description: "个人简介：全面负责产品研发中心的设计与管理。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/wzry-images/wzry-party-2.png"),
                //     name: "王华",
                //     position: "市场部：项目经理",
                //     description: "个人简介：全面负责协调项目的内部和外部业务关系。负责项目的质量、工期、成本、安全和生产价值的完成。\n"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/wzry-images/wzry-party-3.png"),
                //     name: "张龙龙",
                //     position: "研发部：交付经理",
                //     description: "个人简介：负责大数据软件产品面向客户的交付实施工作，协助项目经理按期完成高质量交付项目。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/wzry-images/wzry-party-4.png"),
                //     name: "皇燕军",
                //     position: "研发部：交付经理",
                //     description: "个人简介：负责仿真产品面向客户的交付实施工作，协助项目经理按期完成高质量交付项目。"
                //   },
                //   {
                //     cover: require("@/assets/websiteImages/wzry-images/wzry-party-5.png"),
                //     name: "顾明玲",
                //     position: "研发部:后端开发工程师",
                //     description: "个人简介：负责产品的后端应用设计，技术架构、后端开发,解决系统性能和技术难题。"
                //   }
                // ],
                "list": [
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx",
                    position: "创始人",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx2",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx3",
                    position: "总经理",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx4",
                    position: "研发总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx5",
                    position: "研发副总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx6",
                    position: "市场销售中心总监",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx7",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx8",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx9",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  },
                  {
                    cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
                    name: "xxx10",
                    position: "核心骨干",
                    // description: "毕业院校、专业、承担项目情况。"
                  }
                ],
              }
            },
            {
              "id": 25,
              "title": "核心技术",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "coreTechnology",
                "list": [
                  {
                    "icon": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology-icon1.png"),
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology1.png"),
                    title: "低轨卫星终端",
                    text: "通过主动业务感知、最佳匹配映射等动态集成机制，封装设置相应软件构件，实现基于任务流程的应用系统能力的软件定义，达成动态按需组合。"
                  },
                  {
                    "icon": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology-icon2.png"),
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology2.png"),
                    title: "数据链",
                    text: "技术:具备基于真实数据以及理论、真实环境数据算法等对于高新科技研制进行验证。装备:具备基于现实物理数据、真实装备模型映射至虚拟空间，形成数据与模型交互操作获得近似反应。系统：具备多个装备模型仿真数据动态集成机制，验证各装备对其各类动态数据高效运用。作战：备战场环境仿真、装备仿真，映射真实作战场景，以接近实战复杂情况的训练，提高受训者其装备认知、作战思维。"
                  },
                  {
                    "icon": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology-icon3.png"),
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-coreTechnology3.png"),
                    title: "自组网",
                    text: "统筹国家科学数据资源建设，实现以数据驱动科技创新，形成国军标以及制度规范。"
                  },

                ],

              }
            },
            {
              "id": 26,
              "title": "公司产品",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "companyProducts",
                "productCharacteristicsTitle": "产品特点",
                "productDomainTitle": "应用领域",
                "list": [
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts1.png"),
                    "title": "卫星任务规划",
                    "characteristicsText": "采用模块化、标准化、平台化的设计来构架产品的系统框架，通过对接用户需求输入业务逻辑规则和数据模型。",
                    "domainText": "以组件化、参数化、图形化的分布式应用来搭建开放式、可替换、可组合应用于不同场景不同业务的指控系统软件。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts2.png"),
                    "title": "低空防御",
                    "characteristicsText": "通过主动业务感知、最佳匹配映射等动态集成机制，封装设置相应软件构件，实现基于任务流程的应用系统能力的软件定义，达成动态按需组合。",
                    "domainText": "基于C/S架构的近域低慢小飞行物的防御系统。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts3.png"),
                    "title": "智能驱鸟",
                    "characteristicsText": "通过主动业务感知、最佳匹配映射等动态集成机制，封装设置相应软件构件，实现基于任务流程的应用系统能力的软件定义，达成动态按需组合。",
                    "domainText": "基于C/S架构的机场智能探鸟驱鸟指控系统。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts4.png"),
                    "title": "作战仿真",
                    "characteristicsText": "具备战场环境仿真、装备仿真，映射真实作战场景，以接近实战复杂情况的训练，提高受训者其装备认知、作战思维。",
                    "domainText": "用于提高装备认知以及作战能力的的平台，主要用户在作战和训练部门。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts5.png"),
                    "title": "系统级仿真",
                    "characteristicsText": "具备多个装备模型仿真数据动态集成机制，验证各装备对其各类动态数据高效运用。",
                    "domainText": "用于集成多种仿真环境、装备的集成仿真模拟联动。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts6.png"),
                    "title": "装备级仿真",
                    "characteristicsText": "具备基于现实物理数据，真实装备模型映射至虚拟空间，形成数据与模型交互操作获得近似反应。",
                    "domainText": "用于装备训练的平台，主要用户在装备管理部门和训练。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts7.png"),
                    "title": "信号级仿真",
                    "characteristicsText": "具备基于真实数据以及理论、真实环境数据算法等对于高新科技研制进行验证。",
                    "domainText": "用于技术验证的仿真平台，主要用户在军队或军工科研单位。",
                  },
                  {
                    "cover": require("@/assets/websiteImages/wzry-images/wzry-companyProducts8.png"),
                    "title": "实兵对抗系统",
                    "characteristicsText": "以Unity引擎技术、国产操作系统、国产数据库、国产GIS系统为基础，通过自主研发的编辑器工具，可提供想定数据、模型数据、要素数据、环境构设、任务规划等数据编辑功能，实现了对真实场景的高度还原和逼真模拟，支持多种训练、模拟、教学模式，全方位满足用户对展示内容的规划和要求。",
                    "domainText": "使用主流仿真技术进行军事相关游戏仿真实现。",
                  },
                ]
              }
            },
            {
              "id": 24,
              "title": "合作伙伴",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "partners",
                "cover":require('@/assets/websiteImages/wzry-images/wzry-partners.png')
              }
            },
            {
              "id": 30,
              "title": "企业愿景",
              "rightData": {
                "companyType":"wzryCompany",
                "tabType": "enterpriseVision",
                "cover": require("@/assets/websiteImages/wzry-images/wzry-enterpriseVision1.png"),
              }
            },

          ],

          "cover": require('@/assets/websiteImages/home/BranchCompany1.png'),
          "id": 43,
          "title": "北京王者荣耀科技有限公司",
          "logoText": "王者荣耀",
          "content": "经营范围：游戏仿真平台开发、基于数据驱动应用系统软件设计、大数据平台服务。",
          "logo":require('@/assets/websiteImages/home/wzry-logo.png')
        },


      ],

    };
  },
  methods: {
    handlerTabCompany(itemData) {
      this.$store.commit('setBannerList',itemData.classifyList)
      this.$store.commit('setSonCompanyLogo',itemData.logo)
    }
  },
};
</script>
<style lang="scss">
.branch-company-wrapper {
  border-radius: 5px;
  background: #fff;
  margin: 20px auto;
  padding: 30px 0;

  .title {
    margin-bottom: 20px;
    position: relative;
    .title-text {
      .icon {
        width:30px;
        font-size:30px;
        color:#0080ff;
      }
      .text {
        color: #0080ff;
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
      }
    }
    span {
      width: 50px;
      text-align: center;
      font-size: 14px;
      position: relative;
      cursor: pointer;
      color: #999;

      i {
        position: relative;
        top: 1px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .content {
    .company-card-wrapper {
      cursor: pointer;
      .el-card {
        width: 370px;
        margin: 0 10px;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);

        .content-text {
          padding: 20px 30px 10px;

          p {
            text-align: center;
            width: 100%;
            color: #333;
            font-size: 18px;
            font-weight: bold;
            line-height: 1;

          }

          & p:last-child {
            min-height: 63px;
            margin-top: 10px;
            text-align: left;
            color: #666;
            font-weight: normal;
            font-size: 14px !important;
            line-height: 21px;
          }
        }
        .company-pic {
          width: 100%;
          height: 225px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }
    }
  }
}
</style>
