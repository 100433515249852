<template>
  <div class="banner-wrapper flex-rowStartColCenter">
    <div class="classify-wrapper">
      <classify-item
          :tabList="$store.state.bannerList"
          :activeTab="activeTab"
          @handlerRightMsg="handlerRightMsg"
          ref="bannerTabEl"
      />
    </div>
    <div class="banner-container" :style="{height:bannerInitHeight}">
      <!--      公司简介   -->
      <div class="companyProfile-content content flex-rowStartColStart"
           v-if="globalBannerData.tabType==='companyProfile'">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>
        <div class="content-text-wrapper flex-rowStartColCenter">
          <div class="content-text">
            <div class="title">
              <p>{{ globalBannerData.title }}</p>
            </div>
            <div class="text-wrapper">
              <p v-html="globalBannerData.content"></p>
            </div>

          </div>
        </div>

      </div>
      <!--      企业架构-->
      <div class="companyStructure-content content flex-rowCenterColCenter"
           v-if="globalBannerData.tabType==='companyStructure'">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>


      </div>
      <!--     辰极智航 企业文化-->
      <div class="enterpriseCulture-content content flex-rowStartColStart"
           v-if="globalBannerData.tabType==='enterpriseCulture'&&globalBannerData.companyType==='cjzhCompany'">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>
        <div class="content-list-wrapper">
          <p class="content-list-title">{{ globalBannerData.title }}</p>
          <ul class="enterpriseCulture-list">
            <li
                v-for="(cultureItem,index) in globalBannerData.list"
                :key="index"
                class="flex-rowStartColCenter"
            >
              <p class="keyword-text">{{ cultureItem.text }}</p>
              <p class="text">{{ cultureItem.content }}</p>
            </li>
          </ul>
        </div>
        <div class="right-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover2"
          />
        </div>
      </div>

      <!--     辰极防务 and 王者荣耀 企业文化-->
      <div class="cjfw-enterpriseCulture-content content flex-rowStartColStart"
           v-if="globalBannerData.tabType==='enterpriseCulture'&&(globalBannerData.companyType==='cjfwCompany'||globalBannerData.companyType==='wzryCompany')">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>
        <div class="content-list-wrapper flex-rowCenterColStart">
          <p class="content-list-title">{{ globalBannerData.title }}</p>
          <p class="content-list-text">{{ globalBannerData.content }}</p>
        </div>
        <div class="right-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover2"
          />
        </div>
      </div>

      <!--     辰极智航 公司资质-->
      <div class="companyQualification-content content flex-rowStartColCenter"
           v-if="globalBannerData.tabType==='companyQualification'&&globalBannerData.companyType==='cjzhCompany'">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>
        <div class="content-list-wrapper">
          <ul class="companyQualification-list">
            <li
                v-for="(qualificationItem,index) in globalBannerData.list"
                :key="index"
                :class="{active:index===1||index===3}"
            >
              {{ qualificationItem.text }}
            </li>
          </ul>
        </div>
      </div>
      <!--     王者荣耀 公司资质-->
      <div class="wzry-companyQualification-content content flex-rowBetweenColCenter"
           v-if="globalBannerData.tabType==='companyQualification'&&(globalBannerData.companyType==='wzryCompany'|| globalBannerData.companyType==='cjfwCompany')">
        <div class="content-pic flex-rowCenterColCenter">
          <img
              :alt="globalBannerData.title+'(图)'"
              object-fit="fill"
              v-lazy="globalBannerData.cover"
          />
        </div>
        <div class="content-text">
          <p class="text">
            {{ globalBannerData.content }}
          </p>
        </div>
      </div>

      <!--      公司团队-->
      <div class="companyTeam-content content flex-rowStartColCenter"
           v-if="globalBannerData.tabType==='companyTeam'">
        <vue-seamless-scroll
            :data="globalBannerData.list"
            :class-option="classOption"
            class="warp"
            :style="width=teamBannerAllWidth + 'px'"
        >
          <ul class="ul-item">
            <li class="li-item" v-for="(personnelItem, index) in globalBannerData.list" :key="index"
                ref="teamBannerItemEl">
              <div class="personnel-pic">
                <img
                    :src="personnelItem.cover"

                    alt=""
                />
              </div>
              <div class="personnel-content">
                <p class="name">{{ personnelItem.name }}</p>
                <p class="position">{{ personnelItem.position }}</p>
                <p class="description">{{ personnelItem.description }}</p>
              </div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>


      <!--    辰极智航 核心技术-->
      <div class="coreTechnology-content content flex-rowStartColStart"
           v-if="globalBannerData.tabType==='coreTechnology'&&globalBannerData.companyType==='cjzhCompany'">


        <div class="technical-list-wrapper">
          <div class="technical-pic">
            <img
                v-lazy="globalBannerData.cover"
                alt=""
            />
          </div>
          <ul class="technical-list">
            <li
                class="flex-rowStartColStart"
                v-for="(technicalItem,index) in globalBannerData.list"
                :key="index"
                :class="'technical-'+index"
            >

              <div class="technical-Item-pic" v-if="index%2!==0">
                <img
                    v-lazy="technicalItem.icon"
                    alt=""
                />
              </div>
              <div class="technical-Item-content-wrapper">
                <p class="technical-Item-title">{{ technicalItem.title }}</p>

                <p class="technical-Item-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ technicalItem.text }}</p>

              </div>
              <div class="technical-Item-pic" v-if="index%2===0">
                <img
                    v-lazy="technicalItem.icon"
                    alt=""
                />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--    王者荣耀 核心技术-->
      <div class="wzry-coreTechnology-content content"
           v-if="globalBannerData.tabType==='coreTechnology'&&globalBannerData.companyType==='wzryCompany'">
        <div class="technical-list-wrapper">
          <ul class="technical-list  flex-rowBetweenColStart">
            <li
                v-for="(technicalItem,index) in globalBannerData.list"
                :key="index"
            >
              <div class="technical-Item-icon">
                <img
                    v-lazy="technicalItem.icon"
                    alt=""
                />
              </div>
              <div class="technical-Item-pic">
                <img
                    v-lazy="technicalItem.cover"
                    alt=""
                />
              </div>
              <div class="technical-Item-content-wrapper">
                <p class="technical-Item-title">{{ technicalItem.title }}</p>
                <p class="technical-Item-text">{{ technicalItem.text }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!--    辰极防务 核心技术-->
      <div class="cjfw-coreTechnology-content content flex-rowStartColCenter"
           v-if="globalBannerData.tabType==='coreTechnology'&&globalBannerData.companyType==='cjfwCompany'">
        <div class="technical-list-wrapper">
          <ul class="technical-list  flex-rowBetweenColStart">
            <li
                v-for="(technicalItem,index) in globalBannerData.list"
                :key="index"
            >
              <div class="technical-Item-icon">
                <img
                    v-lazy="technicalItem.icon"
                    alt=""
                />
              </div>
              <p class="technical-Item-title flex-rowCenterColCenter">
                <span>{{ technicalItem.title }}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>



      <!--      公司产品-->
      <div class="companyProducts-content content flex-rowStartColStart"
           v-if="globalBannerData.tabType==='companyProducts'">
        <el-carousel
            :interval="2000"
            :height="bannerInitHeight"
        >
          <el-carousel-item v-for="(productsItem, index) in globalBannerData.list" :key="index">
            <div class="personnel-pic flex-rowCenterColCenter">
              <img
                  v-lazy="productsItem.cover"
                  alt=""
              />
            </div>
            <div class="personnel-content">
              <p class="productTitle">{{ productsItem.title }}</p>
              <p class="productCharacteristicsTitle description">
                <strong>{{ globalBannerData.productCharacteristicsTitle }}:</strong>{{
                  productsItem.characteristicsText
                }}
              </p>
              <p class="productDomainTitle description">
                <strong>{{ globalBannerData.productDomainTitle }}:</strong>{{ productsItem.domainText }}</p>
            </div>

          </el-carousel-item>
        </el-carousel>
      </div>
      <!--      合作伙伴-->
      <div class="partners-content content flex-rowCenterColCenter"
           v-if="globalBannerData.tabType==='partners'">
        <div class="personnel-pic flex-rowCenterColCenter">
          <img
              v-lazy="globalBannerData.cover"
              alt=""
          />
        </div>
      </div>
      <!--      企业愿景-->
      <div class="enterpriseVision-content content flex-rowCenterColCenter"
           v-if="globalBannerData.tabType==='enterpriseVision'">
        <div class="enterprise-pic">
          <img
              v-lazy="globalBannerData.cover"
              alt=""
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import classifyItem from "./classify.vue";
// 图片1920*500
export default {
  name: "banner",
  data() {
    return {
      //选中的tab
      activeTab: "",
      //公司团队 banner初始化
      classOption: {
        autoPlay: true,
        direction: 2,
        hoverStop: true,
        limitMoveNum: 1,
      },
      //公司团队banner总宽度
      teamBannerAllWidth: 0,
      //banner初始高度
      bannerInitHeight: undefined,
      // 分类
      classifyList: [
        {
          "id": 7,
          "title": "公司简介",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "companyProfile",
            "title": "辰极智航（北京）科技有限公司",
            "content": "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极智航（北京）科技有限公司成立于2013年，是一家提供未来智能科技的高新技术企业，设立于北京市丰台区美丽的永定河畔，与美丽的宛平城依依相望，并在全国各地设有分公司。</br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;企业自成立以来，秉持着“技术解读现在，智慧领航未来”的先进理念，始终坚持诚信立业的经营原则，在智能无人系统、未来通信技术、智能防御装备、大数据服务平台、电子元器件及电子学材料、游戏仿真系统等多个领域具有先进理念和关键核心技术，在需求分析、方案论证、系统集成、技术转化等方面具有丰富经验。<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;辰极智航把高精尖技术的研发和应用作为企业战略目标，通过智能化技术改善人们的生活方式，提升我国的高科技智能信息化。",
            "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyProfile.jpg"),
          }
        },
        {
          "id": 11,
          "title": "企业架构",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "companyStructure",
            "cover": require("@/assets/websiteImages/cjzh-images/cjzh-companyStructure.png"),
          }
        },
        {
          "id": 13,
          "title": "企业文化",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "enterpriseCulture",
            "title": "“创、质、和、法”，既是企业的文化，也是对企业和员工的基本要求。",
            "list": [
              {
                text: "创",
                content: "以创新精神做企业，以创新思维研技术。"
              },
              {
                text: "质",
                content: "产品要有过硬质量，做人要有优良品质。"
              },
              {
                text: "和",
                content: "内部关系融洽和气，对外交往合作共赢。"
              },
              {
                text: "法",
                content: "遵守国家法律法规和保密规定，遵守公司规章制度和商业机密。"
              },

            ],
            "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture.png"),
            "cover2": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png")
          }
        },
        {
          "id": 15,
          "title": "公司资质",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "companyQualification",
            list: [
              {
                text: "中关村高新技术企业认定"
              },
              {
                text: "高新技术企业证书"
              },
              // {
              //   text: "武器装备承研承制认证"
              // },
              // {
              //   text: "军工保密二级资质"
              // },
              {
                text: "北京市“专精特新”中小企业"
              },
              {
                text: "知识产权试点单位证书"
              },
            ],
            cover: require('@/assets/websiteImages/cjzh-images/cjzh-companyQualification.png'),
          }
        },
        // {
        //
        //   "id": 9,
        //   "title": "公司团队",
        //   "rightData": {
        //     "companyType": "cjzhCompany",
        //     "tabType": 'companyTeam',
        //     // "list": [
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-1.png"),
        //     //     name: "章双全",
        //     //     position: "研发部：研发总监",
        //     //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-2.png"),
        //     //     name: "马衍青",
        //     //     position: "市场部：市场副总监",
        //     //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-3.png"),
        //     //     name: "李伟",
        //     //     position: "研发部：研发项目经理",
        //     //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-4.png"),
        //     //     name: "郑铁宁",
        //     //     position: "研发部：结构设计",
        //     //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-party-5.png"),
        //     //     name: "苏成臣",
        //     //     position: "研发部：工程师",
        //     //     description: "个人简介：毕业于清华大学材料科学与工程专业，多次承担军委后勤保障部、军科委、装备部等重点项目。"
        //     //   }
        //     // ],
        //     // "list": [
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx",
        //     //     position: "创始人",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx2",
        //     //     position: "总经理",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx3",
        //     //     position: "总经理",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx4",
        //     //     position: "研发总监",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx5",
        //     //     position: "研发副总监",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx6",
        //     //     position: "市场销售中心总监",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx7",
        //     //     position: "核心骨干",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx8",
        //     //     position: "核心骨干",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx9",
        //     //     position: "核心骨干",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   },
        //     //   {
        //     //     cover: require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseCulture2.png"),
        //     //     name: "xxx10",
        //     //     position: "核心骨干",
        //     //     // description: "毕业院校、专业、承担项目情况。"
        //     //   }
        //     // ],
        //   }
        // },
        {
          "id": 25,
          "title": "核心技术",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "coreTechnology",
            "list": [
              {
                icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon1.png"),
                title: "卫星终端",
                text: "可实现高动态环境下低轨卫星信号的快速捕获和稳定跟踪，宽带频率切换下的相控阵天线高精度指向技术，具备低成本、轻量化、低功耗、小型化、低功耗等特点。"
              },
              {
                icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon2.png"),
                title: "数据链",
                text: "具有体系统一、自主演进、弹性互联、韧性顽存、一端多模能力特点的低延时、远距离、小型化的数据链路可靠传输技术，可实现自组网通信链路的远距离高速率多节点长加密。"
              },
              {
                icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon3.png"),
                title: "光电吊舱",
                text: "主要用于收集光电信号的一种任务载荷设备，广泛应用于监测、导航和跟踪等领域。"
              },
              // {
              //   icon: require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology-icon4.png"),
              //   title: "卫星通信終端基带技术",
              //   text: "具备小型化、低功耗特点。"
              // },
            ],
            "cover": require("@/assets/websiteImages/cjzh-images/cjzh-coreTechnology.png"),
          }
        },
        {
          "id": 26,
          "title": "公司产品",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "companyProducts",
            "productCharacteristicsTitle": "产品特点",
            "productDomainTitle": "应用领域",
            "list": [
              // {
              //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts1.png"),
              //   "title": "抛物面天线 RE-T370Z",
              //   "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。",
              //   "domainText": "主要编配于战区及军以上作战单元。",
              // },
              {
                "cover": require("@/assets/websiteImages/cjzh-images/companyProducts2.png"),
                "title": "抛物面天线 RE-Z090B",
                "characteristicsText": "支持Ku频段和Ka频段分时工作；在有信标条件下，可独立完成对星；在无信标条件下，可与卫星信道设备配合完成对星;位置及姿态信息采集；能够存储并调用1000颗以上卫星的星位参数，并支持外部输入星位参数；在自动跟踪模式下，能够根据手动输入或自动获取的位置信息及姿态信息、星位参数自动控制天线对准目标卫星。",
                "domainText": "用于卫星箱式站与固定站、车载站等站型之间语音、短信息、数据业务双向传输。",
              },
              {
                "cover": require("@/assets/websiteImages/cjzh-images/companyProducts3.png"),
                "title": "车载自组网通信设备 RE-A020LY",
                "characteristicsText": "单个设备故障不会影响整个系统其它设备的使用不依赖基站、高效、快速组成无中心的无线宽带网络，支持多跳中继、能有效拓展无线网络的覆盖半径/IP/UDP网络协议线，全IP组网，易于互联，通信距离：单跳传输≥6km（通视环境）。",
                "domainText": "应急通信、车载指挥、森林防火、石油勘探。  ",
              },
              // {
              //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts4.png"),
              //   "title": "抛物面天线 RE-T240Z",
              //   "characteristicsText": "可作为宽带通信网和抗强干扰通信网的业务站，并可在中心站网控的授权下，构建宽带和抗强干扰的虚拟专网，支持虚拟专网的配置管理、性能管理、故障管理和综合态势管理等功能，可接收和订阅上级网络管理节点推送的逻辑子网的网络运行态势信息并进行呈现。支持对天线、发射机、卫星信道设备等主要设备工作状态、参数的监视和控制。支持宽带通信网、抗强干扰通信网之间的话音和 IP 数据业务互联互通。支持宽带通信网、抗强干扰通信网与军用自动电话网之间话音业务互通。",
              //   "domainText": "通用型 2.4 米 Ka/Ku 双频段卫星通信车主要编配于战区及军以上作战单元。",
              // },
              // {
              //   "cover": require("@/assets/websiteImages/cjzh-images/companyProducts5.png"),
              //   "title": "抛物面天线 RE-T050F",
              //   "characteristicsText": "支持抗强电磁脉冲攻击能力；多轨道、有信标、无信标自跟踪能力； 支持 Ka 宽频工作能力；支持宽带、抗强等多种波形应用；支持话音、同/异步数据、IP 数据、短消息和集群等多业务处理； 支持远程接入和组网通信，管理控制、人机交互和升级扩展能力；支持小型化低功耗、快速展开撤收。",
              //   "domainText": "主要编配于核反击作战力量、特种作战部队。",
              // },
              {
                "cover": require("@/assets/websiteImages/cjzh-images/companyProducts6.png"),
                "title": "RE-A020SY",
                "characteristicsText": "支持 7×24 小时持续工作，IP 67 防护等级，防水防盐雾；支持视频、语音、位置、传感器等各类基于 IP 的数据传输;支持自动频谱迁移、跳频工作模式；支持 MIMO 多天线技术，最大通信速率大于 100Mbps。",
                "domainText": "应急通信、岸船通信、森林防火、石油勘探。",
              },
            ]

          }
        },
        {
          "id": 24,
          "title": "合作伙伴",
          "rightData": {
            "companyType": "cjzhCompany",
            "tabType": "partners",
            "cover": require('@/assets/websiteImages/cjzh-images/cjzh-partners.png')
          }
        },
        // {
        //   "id": 30,
        //   "title": "企业愿景",
        //   "rightData": {
        //     "companyType": "cjzhCompany",
        //     "tabType": "enterpriseVision",
        //     "text": "这三年时间为公司稳定发展的成熟期，主要工作是巩固企业成果，全方位立体化的建设公司。在成熟期，公司自主知识产权的科研成果具有国内外领先水平，产品在特定的行业领域和巨大民用市场得到广泛应用，公司建立了优良的品牌和企业诚信，与优良用户建立产期稳定的客户关系，具有完善高效的供应链体系，销售已占有较大的市场规模，同时具有科学合理人才队伍，管理制度具有领先优势，公司已完全具备科创板上市条件。",
        //     "cover": require("@/assets/websiteImages/cjzh-images/cjzh-enterpriseVision1.png"),
        //   }
        // },

      ],
      //右侧信息
      globalBannerData: undefined,
    };
  },
  components: {
    classifyItem,
    vueSeamlessScroll
  },
  created() {
    // this.getCategoryList();
    // this.getBannerList();
    this.globalBannerData = this.classifyList[0].rightData
    this.activeTab = this.globalBannerData.tabType
    this.$store.commit('setBannerList', this.classifyList)
  },
  mounted() {
    this.$nextTick(() => {
      this.bannerInitHeight = this.$refs.bannerTabEl.getTabListHeight()
    })
  },
  watch: {
    globalBannerData: {
      deep: true,
      handler(newValue) {
        if (newValue.tabType === 'companyTeam') {
          this.$nextTick(() => {
            this.teamBannerAllWidth = (this.$refs.teamBannerItemEl[0].offsetWidth) * newValue.list.length
          })
        }
      }
    },
    '$store.state.bannerList'(newValue) {
      this.globalBannerData = newValue[0].rightData
      this.activeTab = this.globalBannerData.tabType
    }
  },

  methods: {
    //处理切换右侧图片
    handlerRightMsg(tabData) {
      this.globalBannerData = tabData.rightData
      this.activeTab = tabData.rightData.tabType

    },

  },
};
</script>
<style lang="scss">
.banner-wrapper {
  margin-bottom: 20px;
  border: 1px solid rgb(64, 158, 255);
  border-radius: 5px;
  overflow: hidden;

  .classify-wrapper {
    width: 250px;
    background: #fff;
  }

  .banner-container {
    flex: 1;
    background-color: #fff;

    .content {
      height: 100%;
    }

    //公司简介
    .companyProfile-content {
      padding: 30px;
      box-sizing: border-box;
      .content-pic {
        margin-right: 20px;
        width: 300px;
        height: 390px;

        img {
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

        //.el-image {
        //  height: 100%;
        //}
      }
      .content-text-wrapper {
        flex: 1;
        height: 390px;
        .content-text {
          .title {
            margin-bottom:20px;
            p {
              font-size: 24px;
              color: #333;
              font-weight: bold;
              text-align: center;
            }
          }

          .text-wrapper {
            p {
              color: #666;
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }



    }

    //企业架构
    .companyStructure-content {
      //.content-pic {
      //  width: 600px;
      //  height:328px;
      //  img {
      //    display: block;
      //    width:100%;
      //    height:100%;
      //
      //  }
      //}
    }

    //辰极智航-企业文化
    .enterpriseCulture-content {
      position: relative;
      padding: 30px;
      box-sizing: border-box;

      .content-pic {

        width: 300px;
        height: 390px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

      }

      .content-list-wrapper {
        margin-left: 20px;
        margin-top: 10px;

        .content-list-title {
          font-size: 18px;
          color: #333;
          font-weight: bold;
        }

        .enterpriseCulture-list {
          li {
            margin: 20px 0;

            .keyword-text {
              width: 40px;
              line-height: 40px;
              background-color: #315699;
              color: #fff;
              font-size: 18px;
              text-align: center;
              font-weight: bold;
              border-radius: 50%;
              margin-right: 30px;
            }

            .text {
              font-size: 14px;
              color: #333;

            }
          }
        }
      }

      .right-pic {
        position: absolute;
        right: 30px;
        bottom: 30px;
      }
    }

    //王者荣耀-企业文化
    .wzry-enterpriseCulture-content {
      position: relative;

      .content-pic {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

      }

      .content-text {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        p {
          text-align: center;
          color: #fff;
          line-height: 21px;
        }

        .title {
          font-size: 18px;
          margin-bottom: 20px;
        }

        .text {
          font-size: 14px;

        }
      }
    }

    //辰极防务-企业文化
    .cjfw-enterpriseCulture-content {
      position: relative;
      padding: 30px;
      box-sizing: border-box;
      .content-pic {
        width: 460px;
        height: 390px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 5px;
        }

      }

      .content-list-wrapper {
        margin-left: 20px;
        height:195px;
        flex-direction: column;
        .content-list-title {
          font-size: 24px;
          color: #333;
          font-weight: bold;
          margin-bottom:15px;
        }
        .content-list-text {
          font-size:14px;
          line-height: 21px;
          width:410px;
          color:#666;
        }

      }

      .right-pic {
        position: absolute;
        right: 30px;
        bottom: 45px;
      }
    }


    //辰极-企业资质
    .companyQualification-content {
      justify-content: space-around;

      .content-pic {

      }

      .content-list-wrapper {
        .companyQualification-list {
          li {
            width: 405px;
            line-height: 50px;
            background-color: #507DC8;
            color: #fff;
            text-align: center;
            margin-bottom: 20px;

            &.active {
              background-color: #0E4D90;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    //王者-企业资质
    .wzry-companyQualification-content {
      padding: 30px;
      box-sizing: border-box;
      flex-direction: column;
      .content-pic {

      }

      .content-text {
        margin-top: 30px;

        p {
          font-size: 14px;
          line-height: 21px;
          color: #333;
          text-align: center;
        }
      }
    }

    //企业团队
    .companyTeam-content {
      padding: 30px;
      box-sizing: border-box;

      .el-carousel {
        width: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .warp {

        overflow: hidden;

        ul {
          list-style: none;
          padding: 0;
          margin: 0 auto;

          &.ul-item {
            display: flex;
            flex-wrap: nowrap;

            .li-item {
              width: 160px;
              padding-right: 20px;

              .personnel-pic {
                width: 100%;
                height: 210px;

                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
            }
          }
        }
      }

      .personnel-content {
        p {
          margin: 10px 0;
          line-height: 21px;
        }

        .name {
          font-size: 18px;
          font-weight: bold;

          color: #333;
        }

        .position {
          font-size: 14px;
          color: #666;
        }

        .description {
          font-size: 14px;
          color: #333;
        }
      }
    }

    //辰极智航-核心技术
    .coreTechnology-content {
      position: relative;

      .technical-list-wrapper {
        width: 210px;
        height: 320px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .technical-pic {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;

          img {
            display: block;
            widht: 100%;
            height: 100%;
          }
        }

        .technical-list {
          li {
            position: absolute;

            &.technical-0 {
              left: -340px;
              top: 30px;

              .technical-Item-content-wrapper {
                margin-right: 20px;

                .technical-Item-title {
                  text-align: right;
                }
              }
            }

            &.technical-1 {
              right: -340px;
              top: 120px;

              .technical-Item-content-wrapper {
                margin-left: 20px;

              }
            }

            &.technical-2 {
              left: -340px;
              bottom: 30px;

              .technical-Item-content-wrapper {
                margin-right: 20px;

                .technical-Item-title {
                  text-align: right;
                }
              }
            }

            &.technical-3 {
              right: -340px;
              bottom: 30px;

              .technical-Item-content-wrapper {
                margin-left: 20px;
              }
            }

            .technical-Item-pic {
              margin-top:-4px;
              img {
                display: block;
                width: 40px;
                height: 40px;
              }
            }

            .technical-Item-content-wrapper {
              width: 260px;

              .technical-Item-title {
                margin-bottom: 20px;
                font-size: 18px;
                line-height: 1;
                font-weight: bold;
                color: #333;
                text-align: left;
              }

              .technical-Item-text {
                font-size: 14px;
                line-height: 21px;
                color: #666;
              }
            }
          }
        }
      }
    }

    //王者荣耀-核心技术
    .wzry-coreTechnology-content {
      padding: 30px;
      box-sizing: border-box;

      .technical-list-wrapper {
        width:100%;
        .technical-list {
          width:100%;
          li {
            .technical-Item-icon {
              margin-bottom:10px;
              img {
                display: block;
                margin: 0 auto;
              }
            }
            .technical-Item-pic {
              img {
                display: block;
                margin: 0 auto;
              }
            }
            .technical-Item-content-wrapper {
              width: 270px;

              .technical-Item-title {
                font-size: 18px;
                line-height: 21px;
                font-weight: bold;
                color: #333;
                text-align: center;
                margin: 20px 0;
              }

              .technical-Item-text {
                font-size: 14px;
                line-height: 21px;
                color: #666;
                text-align: center;
              }
            }


          }
        }

      }
    }

    //辰极防务-核心技术
    .cjfw-coreTechnology-content {
      padding: 30px;
      box-sizing: border-box;
      .technical-list-wrapper {
        width:100%;
        .technical-list {

          width:100%;
          flex-wrap: wrap;
          li {
            position: relative;
            width:230px;
            height:160px;
            padding: 20px 0 0 20px;
            box-sizing: border-box;
            margin: 0 20px 20px 0;
            .technical-Item-icon {
              position: absolute;
              left:0;
              top:0;
              img {
                display: block;
              }
            }
            .technical-Item-title {
              padding:30px;
              height: 100%;
              box-sizing: border-box;
              background-color: #F3F8FF;
              span {
                font-size: 14px;
                font-weight: bold;
                color: #333;
                line-height: 21px;
              }
            }
          }
        }

      }
    }


    //公司产品
    .companyProducts-content {
      .el-carousel {
        width: 100%;

        .el-carousel__item {
          padding: 30px;
          box-sizing: border-box;
          display: flex !important;
          flex-direction: column;
          align-items: center;
          .personnel-pic {
            flex:1;
          }

          .personnel-content {
            .productTitle {
              font-size: 18px;
              font-weight: bold;
              color: #333;
              text-align: center;
              line-height: 30px;
            }

            .description {
              font-size: 14px;
              color: #333;
              line-height: 21px;

              strong {
                font-weight: bold;
              }
            }

          }
        }
      }
    }

    //合作伙伴
    .partners-content {
      padding: 30px 30px;
      box-sizing: border-box;
    }

    //企业愿景
    .enterpriseVision-content {
      .enterprise-pic {
        width:100%;
        height:100%;
        img {
          display: block;
          width:100%;
          height:100%;
        }
      }
    }
  }
}

</style>
